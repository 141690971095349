@font-face {
    font-family: "CemtrikIcons";
    src: url("fonts/CemtrikIcons.eot?awznlr");
    src: url("fonts/CemtrikIcons.eot?awznlr#iefix") format("embedded-opentype"),
        url("fonts/CemtrikIcons.ttf?awznlr") format("truetype"),
        url("fonts/CemtrikIcons.woff?awznlr") format("woff"),
        url("fonts/CemtrikIcons.svg?awznlr#CemtrikIcons") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.cemtrik-icons i {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "CemtrikIcons" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.cemtrik-bold-start-o:before {
    content: "\e900";
}
.cemtrik-bold-start:before {
    content: "\e901";
}
.cemtrik-heart-o:before {
    content: "\e903";
}
.cemtrik-heart:before {
    content: "\e909";
}
.cemtrik-light-start-o:before {
    content: "\e90a";
}
.cemtrik-light-start:before {
    content: "\e90b";
}
.cemtrik-in-love:before {
    content: "\e902";
}
.cemtrik-neutral:before {
    content: "\e904";
}
.cemtrik-sad:before {
    content: "\e905";
}
.cemtrik-semi-neutral:before {
    content: "\e906";
}
.cemtrik-smile:before {
    content: "\e907";
}
.cemtrik-yelling:before {
    content: "\e908";
}
